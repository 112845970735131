<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-8">
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="card-header-padding">
                <div class="d-flex align-center">
                  <div>
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      Allocation
                    </h5>
                    <!-- <p class="text-sm text-body mb-0">
                      Possible states of a document
                    </p> -->
                  </div>
                </div>
              </div>

              <v-card-text>
                <v-form ref="frm">
                  <v-card-text class="px-0 py-0">
                    <v-row>
                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"
                          ><span class="red--text"><strong>* </strong></span
                          >Auction center</label
                        >

                        <v-autocomplete
                          :items="auctionCenter"
                          item-text="auction_center_name"
                          item-value="id"
                          hide-details="auto"
                          v-model="editedItem.auctionCenter"
                          @change="resetSeason"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mt-3
                            mb-0
                          "
                          outlined
                          height="46"
                          single-line
                          :rules="[(v) => !!v || 'Auction center is required']"
                          placeholder="Select auction center"
                        >
                          <template v-slot:selection="{ item }">
                            <v-text
                              label
                              color="bg-default"
                              class="py-1 px-2 my-0"
                            >
                              <span class="text-caption ls-0">{{
                                item.auction_center_name
                              }}</span>
                            </v-text>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"
                          ><span class="red--text"><strong>* </strong></span
                          >Auctioneer</label
                        >
                        <v-autocomplete
                          :items="broker"
                          item-value="id"
                          item-text="broker_name"
                          v-model="editedItem.broker"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            select-style
                            mt-3
                          "
                          :rules="[
                            (v) => v.length > 0 || 'Auctioneer is required',
                          ]"
                          return-object
                          outlined
                          chips
                          multiple
                          height="46"
                          single-line
                          placeholder="Select Auctioneer"
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip
                              v-if="index === 0"
                              label
                              color="bg-default"
                              class="py-1 px-2 my-0"
                            >
                              <span class="text-white text-caption ls-0"
                                >{{ item.broker_code }}-{{
                                  item.broker_name
                                }}</span
                              >
                            </v-chip>
                            <span
                              v-if="index === 1"
                              class="grey--text text-caption"
                            >
                              (+{{ editedItem.broker.length - 1 }}
                              others)
                            </span>
                          </template>
                          <template v-slot:prepend-item>
                            <v-list-item
                              ripple
                              @mousedown.prevent
                              @click="toggleAuctioneer"
                            >
                              <v-list-item-action>
                                <v-icon
                                  :color="
                                    broker.length > 0 ? 'indigo darken-4' : ''
                                  "
                                >
                                  {{ iconAuctioneer }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Select All
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"
                          ><span class="red--text"><strong>* </strong></span
                          >Season</label
                        >

                        <v-autocomplete
                          :items="season"
                          item-value="id"
                          hide-details="auto"
                          v-model="editedItem.season"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mt-3
                            mb-0
                          "
                          return-object
                          outlined
                          height="46"
                          @change="filterSeasonSale()"
                          single-line
                          :rules="[(v) => !!v || 'Season is required']"
                          placeholder="Select Season"
                        >
                          <template v-slot:selection="{ item }">
                            <v-text
                              name="ss_name"
                              label
                              color="bg-default"
                              class="py-1 px-2 my-0"
                            >
                              <span class="text-caption ls-0">{{
                                item.name
                              }}</span>
                            </v-text>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-text
                              name="ss_name"
                              label
                              color="bg-default"
                              class="py-1 px-2 my-0"
                            >
                              <span class="text-caption ls-0">{{
                                item.name
                              }}</span>
                            </v-text>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"
                          ><span class="red--text"><strong>* </strong></span
                          >Sale No</label
                        >

                        <v-autocomplete
                          :items="seasonSale"
                          item-value="id"
                          hide-details="auto"
                          v-model="editedItem.seasonSale"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mt-3
                            mb-0
                          "
                          return-object
                          outlined
                          height="46"
                          single-line
                          :rules="[(v) => !!v || 'Sale No is required']"
                          placeholder="Select Sale No"
                        >
                          <template v-slot:selection="{ item }">
                            <v-text
                              name="ss_name"
                              label
                              color="bg-default"
                              class="py-1 px-2 my-0"
                            >
                              <span class="text-caption ls-0">{{
                                item.season_sale
                              }}</span>
                            </v-text>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-text
                              name="ss_name"
                              label
                              color="bg-default"
                              class="py-1 px-2 my-0"
                            >
                              <span class="text-caption ls-0">{{
                                item.season_sale
                              }}</span>
                            </v-text>
                          </template>
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"
                          ><span class="red--text"><strong>* </strong></span>Tea
                          type</label
                        >
                        <v-autocomplete
                          :items="teaType"
                          item-value="id"
                          item-text="tea_type_name"
                          v-model="editedItem.teaType"
                          return-object
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            select-style
                            mt-3
                          "
                          outlined
                          chips
                          multiple
                          height="46"
                          single-line
                          placeholder="Select Tea type"
                          :rules="[(v) => !!v || 'Tea type is required']"
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip
                              v-if="index === 0"
                              label
                              color="bg-default"
                              class="py-1 px-2 my-0"
                            >
                              <span class="text-white text-caption ls-0">{{
                                item.tea_type_name
                              }}</span>
                            </v-chip>
                            <span
                              v-if="index === 1"
                              class="grey--text text-caption"
                            >
                              (+{{ editedItem.teaType.length - 1 }}
                              others)
                            </span>
                          </template>
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"
                          >Category</label
                        >
                        <v-autocomplete
                          :items="category"
                          item-value="id"
                          item-text="category_name"
                          hide-details="auto"
                          v-model="editedItem.category"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mt-3
                            mb-0
                          "
                          single-line
                          outlined
                          height="46"
                          placeholder="Select Category"
                        >
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="12" sm="6" md="2" xs="12" lg="2">
                        <label class="text-md text-typo font-weight-bolder ms-1"
                          ><span class="red--text"><strong>* </strong></span
                          >Grade</label
                        >
                        <v-autocomplete
                          :items="grade"
                          item-value="id"
                          item-text="grade_name"
                          v-model="editedItem.grade"
                          return-object
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            select-style
                            mt-3
                          "
                          outlined
                          chips
                          multiple
                          height="46"
                          single-line
                          placeholder="Select Grade"
                          :rules="[(v) => v.length > 0 || 'Grade is required']"
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip
                              v-if="index === 0"
                              label
                              color="bg-default"
                              class="py-1 px-2 my-0"
                            >
                              <span class="text-white text-caption ls-0">{{
                                item.grade_code
                              }}</span>
                            </v-chip>
                            <span
                              v-if="index === 1"
                              class="grey--text text-caption"
                            >
                              (+{{ editedItem.grade.length - 1 }}
                              others)
                            </span>
                          </template>
                          <template v-slot:prepend-item>
                            <v-list-item
                              ripple
                              @mousedown.prevent
                              @click="toggle"
                            >
                              <v-list-item-action>
                                <v-icon
                                  :color="
                                    grade.length > 0 ? 'indigo darken-4' : ''
                                  "
                                >
                                  {{ icon }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Select All
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="2" xs="12" lg="2">
                        <label
                          class="text-md text-typo font-weight-bolder ms-1"
                        >
                          From price</label
                        >
                        <v-text-field
                          v-model.number="editedItem.fromPrice"
                          hide-details="auto"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            input-icon
                            mt-3
                          "
                          dense
                          flat
                          filled
                          solo
                          height="46"
                          placeholder="From price"
                          persistent-hint
                          :rules="[
                            (v) =>
                              (!isNaN(parseFloat(v)) && v >= 0) ||
                              'Price should be a number, 0 or greater',
                          ]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="2" xs="12" lg="2">
                        <label
                          class="text-md text-typo font-weight-bolder ms-1"
                        >
                          To price</label
                        >
                        <v-text-field
                          v-model.number="editedItem.toPrice"
                          hide-details="auto"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            input-icon
                            mt-2
                          "
                          dense
                          flat
                          filled
                          solo
                          height="46"
                          placeholder="To price"
                          :rules="[
                            (v) =>
                              (!isNaN(parseFloat(v)) && v >= 0) ||
                              'Price should be a number, 0 or greater',
                          ]"
                          persistent-hint
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-actions dense class="card-padding d-flex justify-end">
                    <v-btn
                      @click="close"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-bold
                        text-capitalize
                        btn-ls btn-secondary
                        bg-light
                        px-6
                      "
                      >Clear</v-btn
                    >

                    <v-btn
                      @click="searchCatalogue()"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      dark
                      class="
                        font-weight-bold
                        text-capitalize
                        btn-ls btn-primary
                        bg-success
                        px-6
                      "
                      >Search</v-btn
                    >
                  </v-card-actions>
                </v-form>
              </v-card-text>
              <v-card-text
                id="scroll_1"
                class="px-0 py-0"
                v-if="items.length > 0"
              >
                <v-data-table
                  v-model="selected"
                  :headers="headers"
                  :items="items"
                  item-key="cat_id"
                  class="table"
                  :footer-props="{
                    'items-per-page-options': [100, 200, 500, -1],
                  }"
                  show-select
                  mobile-breakpoint="0"
                  fixed-header
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Allocation</v-toolbar-title>

                      <v-spacer></v-spacer>
                      <v-btn
                        @click="allocate"
                        elevation="0"
                        :ripple="false"
                        height="43"
                        dark
                        class="
                          font-weight-bold
                          text-capitalize
                          btn-ls btn-primary
                          bg-success
                          py-3
                          px-6
                        "
                        >Allocate
                      </v-btn>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.auction_date`]="{ item }">
                    <span>
                      {{ formatDate(item.auction_date) }}
                    </span>
                  </template>
                  <template v-slot:[`item.gp_date`]="{ item }">
                    <span>
                      {{ formatDate(item.gp_date) }}
                    </span>
                  </template>
                </v-data-table>
              </v-card-text>
              <v-card-actions v-if="items.length > 0">
                <v-row>
                  <v-col offset-md="5">
                    <v-btn
                      @click="allocate"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      dark
                      class="
                        font-weight-bold
                        text-capitalize
                        btn-ls btn-primary
                        bg-success
                        py-3
                        px-6
                      "
                      >Allocate
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card class="card-shadow border-radius-xl">
        <v-card-title>
          <h5 class="font-weight-bold text-h5 text-typo mb-0">
            Allocate
          </h5></v-card-title
        >
        <v-card-text class="card-padding">
          <v-form ref="frmSave">
            <v-row>
              <v-col>
                <label
                  class="text-md text-typo font-weight-bolder ms-1 mandatory"
                  ><span class="red--text"><strong>* </strong></span>List
                  Name</label
                >
                <v-text-field
                  v-model="editedItem.list_name"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-3
                  "
                  dense
                  flat
                  filled
                  solo
                  height="46"
                  placeholder="Enter List Name"
                  :rules="[(v) => !!v || 'List Name is required']"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col>
                <label
                  class="text-md text-typo font-weight-bolder ms-1 mandatory"
                  ><span class="red--text"><strong>* </strong></span>List
                  Role</label
                >
                <v-autocomplete
                  v-model="editedItem.role"
                  :items="roles"
                  item-text="role"
                  item-value="id"
                  return-object
                  color="rgba(0,0,0,.6)"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    border-radius-md
                    select-style
                    mt-3
                    mb-0
                  "
                  placeholder="Select a Role"
                  :rules="[(v) => !!v || 'Role is Required']"
                  outlined
                  single-line
                  height="46"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions dense class="card-padding d-flex justify-end">
          <v-btn
            @click="closeDialogue"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-secondary
              bg-light
              px-6
            "
            >Cancel</v-btn
          >
          <v-btn
            @click="saveData"
            elevation="0"
            :ripple="false"
            height="43"
            dark
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-success
              py-3
              px-6
            "
            >Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import { format } from "date-fns";
import api from "../api";
import apiMaster from "../../../master/master-get-api";
export default {
  name: "Allocation",
  data() {
    return {
      page: 1,
      pageCount: 0,
      overlay: false,
      auctionCenter: [],
      seasonSale: [],
      broker: [],
      teaType: [],
      category: [],
      grade: [],
      selected: [],
      roles: [],
      season: [],
      singleSelect: true,
      itemsPerPage: 10,
      dialog: false,
      dialogDelete: false,
      items: [],
      search: "",
      editedIndex: -1,
      editedItem: {
        auctionCenter: null,
        seasonSale: null,
        broker: [],
        teaType: null,
        category: 0,
        grade: [],
        fromPrice: 0,
        toPrice: 0,
        role: null,
        list_name: "",
        season: null, // not used in API- just to filter Sale No
      },
      defaultItem: {
        auctionCenter: null,
        seasonSale: null,
        broker: null,
        teaType: 0,
        category: 0,
        grade: 0,
        fromPrice: 0,
        toPrice: 0,
        role: null,
        list_name: "",
        season: null, // not used in API- just to filter Sale No
      },
      headers: [
        { text: "lot no", width: 120, value: "lot_no" },
        { text: "mark", width: "150px", value: "mark_name" },
        { text: "grade", width: 100, value: "grade_name" },
        { text: "invoice no", width: "100", value: "invoice_no" },
        { text: "base price", width: "100", value: "base_price" },
        { text: "Bags", width: 80, value: "no_packages" },
        // { text: "tea type", width: "100", value: "tea_type" },
        { text: "net wt", width: "50", value: "net_weight" },
        { text: "gp date", width: "100", value: "gp_date" },
        { text: "gp no", width: "50", value: "gp_no" },
        {
          text: "period of manufacture",
          width: "150px",
          value: "period_of_manufacture",
        },
        // { text: "category", width: "110", value: "category_name" },

        { text: "package no", width: "100", value: "package_no" },
        { text: "warehouse name", width: "100", value: "warehouse_name" },

        { text: "last sold price", width: "100", value: "last_sold_price" },
      ],
    };
  },

  created() {
    this.initialize();
  },
  methods: {
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    validate() {
      return this.$refs.frm.validate();
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
        showConfirmButton: false,
      });
    },
    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",
        timer: 3000,
        icon: "error",
        showConfirmButton: false,
      });
    },
    noDataAlert(message) {
      this.$swal({
        text: message,

        timer: 3000,

        showConfirmButton: false,
      });
    },

    async initialize() {
      try {
        this.auctionCenter = await apiMaster.getAuctionCenter();

        // this.seasonSale = await apiMaster.getSeasonSale();

        this.teaType = await apiMaster.getTeaType();
        this.category = await apiMaster.getCategory();
        this.grade = await apiMaster.getGrade();
        this.roles = await apiMaster.getRole();
        this.season = await apiMaster.getSeason();
      } catch (err) {
        this.showErrorAlert(err);
      }
    },

    async filterSeasonSale() {
      if (this.editedItem.auctionCenter) {
        this.overlay = true;
        this.editedItem.seasonSale = {};
        this.seasonSale = await apiMaster.getSeasonSaleBySeasonAndAuctionCenter(
          this.editedItem.season.id,
          this.editedItem.auctionCenter
        );
        this.overlay = false;
      } else {
        this.editedItem.season = null;
      }
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    async deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.closeDelete();
      try {
        await api.delete(this.editedItem.id);
        this.items.splice(this.editedIndex, 1);

        this.showSuccessAlert(`Allocation Deleted.`);
      } catch (error) {
        this.showErrorAlert(error.message);
      }
    },

    closeDialogue() {
      this.dialog = false;
      this.$refs.frmSave.reset();
    },
    close() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.reset();
        this.items = [];
        this.selected = [];
      });
    },
    reset() {
      // this.$refs.frm.reset();
      this.editedItem = {
        auctionCenter: 0,
        seasonSale: 0,
        broker: [],
        teaType: null,
        category: 0,
        grade: [],
        fromPrice: 0,
        toPrice: 0,
        role: null,
        list_name: "",
        season: 0, // not used in API- just to filter Sale No
      };
      this.$refs.frm.resetValidation();
      this.editedItem = this.defaultItem;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    searchValidation() {
      return this.$refs.frmFilter.validate();
    },
    saveValidation() {
      return this.$refs.frmSave.validate();
    },

    async searchCatalogue() {
      if (this.validate()) {
        this.overlay = true;
        try {
          this.selected = [];
          this.items = [];
          let slug = "";
          slug += "auction_center=" + this.editedItem.auctionCenter;
          slug += "&season_sale=" + this.editedItem.seasonSale.id;
          slug += this.editedItem.category
            ? "&category_id=" + this.editedItem.category
            : "";

          for await (const key of this.editedItem.broker) {
            slug += "&auctioneer=" + key.id;
          }
          for await (const key of this.editedItem.teaType) {
            slug += "&tea_type_id=" + key.id;
          }
          if (this.grade.length == this.editedItem.grade.length) {
            for await (const key of this.editedItem.grade) {
              slug += "&grade=" + key.id;
            }
          } else {
            for await (const key of this.editedItem.grade) {
              slug += "&grade=" + key.id;
            }
          }

          slug += "&from_price=" + this.editedItem.fromPrice;
          slug += "&to_price=" + this.editedItem.toPrice;
          this.items = await api.getFilteredCatalogue(slug);
          if (this.items.length > 0) {
            this.scrollDown();
            // this.editedItem.broker = [];
          } else {
            this.noDataAlert("No Data Found");
          }
        } catch (err) {
          this.showErrorAlert(err.message);
        } finally {
          this.overlay = false;
        }
      }
    },
    scrollDown() {
      this.$nextTick(() =>
        document
          .getElementById("scroll_1")
          .scrollIntoView({ behavior: "smooth" })
      );
    },

    async allocate() {
      if (this.selected.length > 0) {
        this.editedItem.list_name = "";
        let listName = [];

        let broker = [];

        let teatype = [];
        this.selected.forEach((el) => {
          broker.push(el.broker_code);

          teatype.push(el.tea_type_name);
        });

        let _broker = [...new Set(broker)];

        let _teatype = [...new Set(teatype)];

        let c =
          _broker.sort().join("-") +
          "-" +
          _teatype.sort().join("-") +
          "-" +
          this.editedItem.season.name +
          "-" +
          this.editedItem.seasonSale.season_sale;

        let count = await api.getListCount(c);

        this.editedItem.list_name = c + "-" + count[0].count;

        this.dialog = true;
      } else {
        this.showWarningAlert("Check at least One Row");
      }
    },

    async saveData() {
      if (this.saveValidation()) {
        this.overlay = true;
        let req = {
          season_sale: this.editedItem.seasonSale.id,
          list_name: this.editedItem.list_name,
          role: this.editedItem.role,
          auction_center: this.editedItem.auctionCenter,
          child: [],
        };
        // let child = [];
        this.selected.forEach((el) => {
          let d = {
            cat_id: el.cat_id,

            id: el.cat_id,

            // item_name: el.item_name,
            lot_no: el.lot_no,
            price_valuation: el.price_valuation,
            leaf_appearance: el.leaf_appearance,
            color: el.color,
            body: el.body,
            taste: el.taste,
            comment: el.comment,
          };
          req.child.push(d);
        });

        // Object.assign(req, { child });

        try {
          await api.saveData(req);
          this.showSuccessAlert("Data saved");
        } catch (error) {
          this.showErrorAlert(error);
        } finally {
          this.overlay = false;
          this.dialog = false;
          this.selected = [];
          this.items = [];
          this.reset();
        }

        this.editedItem.list_name = "";
        this.editedItem.role = null;
      }
    },
    async resetSeason() {
      this.broker = [];
      this.editedItem.broker = [];
      this.broker = await apiMaster.getBrokersWithAuctionCenter(
        this.editedItem.auctionCenter
      );
      this.editedItem.season = null;
      this.editedItem.seasonSale = null;
      this.seasonSale = [];
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectAll) {
          this.editedItem.grade = [];
        } else {
          this.editedItem.grade = this.grade.slice();
        }
      });
    },
    toggleAuctioneer() {
      this.$nextTick(() => {
        if (this.selectAllAuctioneer) {
          this.editedItem.broker = [];
        } else {
          this.editedItem.broker = this.broker.slice();
        }
      });
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Allocation" : "Edit Allocation";
    },
    selectAll() {
      return this.editedItem.grade.length === this.grade.length;
    },
    selectSome() {
      return this.editedItem.grade.length > 0 && !this.selectAll;
    },
    icon() {
      if (this.selectAll) return "ni-fat-remove text-lg";
      if (this.selectSome) return "ni-fat-delete";
      return "fa-square-o";
    },
    selectAllAuctioneer() {
      return this.editedItem.broker.length === this.broker.length;
    },
    selectSomeAuctioneer() {
      return this.editedItem.broker.length > 0 && !this.selectAllAuctioneer;
    },
    iconAuctioneer() {
      if (this.selectAllAuctioneer) return "ni-fat-remove text-lg";
      if (this.selectSomeAuctioneer) return "ni-fat-delete";
      return "fa-square-o";
    },
  },
};
</script>
