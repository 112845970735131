import axios from "axios";
const API = "catalogue-upload";

export default {
  async getFilteredCatalogue(slug) {
    return await axios
      .get("catalogue-upload/catalogue-filter?" + slug)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async saveData(data) {
    return axios
      .post("auction-allocation", data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getListCount(list_name) {
    return await axios
      .get("Catalogue-Upload/get-list-count/" + list_name)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
};
